<template>
	<div  v-loading="loading" >
		<el-descriptions :column="2">
		    <el-descriptions-item label="代理昵称">{{detail.agent.name}}</el-descriptions-item>
			<el-descriptions-item label="联系电话">{{detail.agent.phone}}</el-descriptions-item>
			<el-descriptions-item label="税源昵称">{{detail.service.name}}</el-descriptions-item>
			<el-descriptions-item label="审核状态">
				<span v-if="detail.status==0" >待审核</span>
				<span v-else-if="detail.status==1">已通过</span>
				<span v-else-if="detail.status==2" >已驳回</span>
			</el-descriptions-item>
			<el-descriptions-item label="申请原因"><TextOverflow>{{detail.desc}}</TextOverflow></el-descriptions-item>
			<el-descriptions-item v-if="detail.status==2" label="驳回原因"><TextOverflow>{{detail.desc}}</TextOverflow></el-descriptions-item>
		</el-descriptions>
		<div class="drawer-footer" v-if="detail.status==0">
			<Button style="margin-right: 8px" @click="dialogFormVisible = true">驳回</Button>
			<Button type="primary"  @click="adopt()">通过</Button>
		</div>
		<el-dialog title="驳回" width="30%" :visible.sync="dialogFormVisible" append-to-body>
		  <el-form :model="form">
		    <el-form-item label="驳回原因" label-width="80px">
				<el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="form.result"></el-input>
		    </el-form-item>
		  </el-form>
		  <div slot="footer" class="dialog-footer">
		    <el-button @click="dialogFormVisible = false">取 消</el-button>
		    <el-button type="primary" @click="reject()">确 定</el-button>
		  </div>
		</el-dialog>
		<!-- <el-dialog title="通过" width="30%" :visible.sync="adoptVisible" append-to-body>
		  <el-form :model="form">
		    <el-form-item label="费率" label-width="80px">
				<el-input  placeholder="请输入费率" v-model="form.rate"></el-input>
		    </el-form-item>
		  </el-form>
		  <div slot="footer" class="dialog-footer">
		    <el-button @click="adoptVisible = false">取 消</el-button>
		    <el-button type="primary" @click="adopt()">确 定</el-button>
		  </div>
		</el-dialog> -->
	</div>
</template>

<script>
	import {request} from "@/library/request.js";
	import TextOverflow from "components/common/TextOverflow.vue";
	export default {
		components:{
			TextOverflow
		},
		data (){
			return{
				loading: false,
				id:0,
				adoptVisible: false,
				dialogFormVisible: false,
				detail:{
					agent:{
						name:"",
						phone:"",
					},
					service:{
						name:""
					},
					status:0,
					desc:"",
					rate:0
				},
				form:{
					rate:0,
					result:"",
				}
			}
		},
		methods:{

			getList(){
				this.loading = true

				return request({
					url:'cooperation/detail/'+this.id,
					method:"get",
				}).then(response  => {
					this.loading = false
					this.detail = response.data
					
				}).then(err => {
					if(err){
						this.loading = false
						this.$Message.error("系统异常，请稍后重试")
					}
				})
			},
			adopt(){
				return request({
					url:'cooperation/agree/'+this.id,
					method:"get",
				}).then(response  => {
					let that = this
					console.log(response)
					if(response.code==200){
						this.$Message.success({
							content:response.msg,
							duration:1.5,
							onClose:function(){	
								that.adoptVisible = false
								that.$emit('routerCancel',true)
							}
						})
					}else{
						this.$Message.error({
							content:response.msg,
							duration: 3
						})
					}
					// this.detail = response.data
					
				}).then(err => {
					if(err){
						this.loading = false
						this.$Message.error("系统异常，请稍后重试")
					}
				})
			},
			reject(){
				var data = {
					id:this.id,
					result:this.form.result
				}
				return request({
					url:'cooperation/reject',
					method:"post",
					params:data
				}).then(response  => {
					let that = this
					if(response.code==200){
						this.$Message.success({
							content:response.msg,
							duration:1.5,
							onClose:function(){	
								that.dialogFormVisible = false
								that.$emit('routerCancel',true)
							}
						})
					}else{
						this.$Message.error({
							content:response.msg,
							duration: 3
						})
					}
					// this.detail = response.data
					
				}).then(err => {
					if(err){
						this.loading = false
						this.$Message.error("系统异常，请稍后重试")
					}
				})
				
			}
		},
		mounted() {

			this.id = this.$route.params.id
			this.getList()
			
		}
	}
</script>

<style>
	.drawer-footer{
		width: 100%;
		position: absolute;
		bottom: 0;
		left: 0;
		padding: 10px 16px;
		text-align: right;
		background: #fff;
	}
</style>
